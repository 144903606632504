import React, { useState, useEffect } from "react"
import { graphql } from "gatsby"
import styled, { css } from "styled-components"
import { theming, Layout, Wrapper } from "@staccx/base"
import PageLayout from "../components/PageLayout"
import DefaultLayout from "../layouts"
import GraphQLErrorList from "../components/graphql-error-list"
import Search from "../components/Search"
import SEO from "../components/seo"
import MostPopular from "../components/MostPopular"

export const query = graphql`
  query IndexPageQuery {
    site: sanitySiteSettings(_id: { regex: "/(drafts.|)siteSettings/" }) {
      title
      description
      keywords
    }
    posts: allSanityPost(
      sort: { fields: [publishedAt], order: DESC }
      filter: {
        slug: { current: { ne: null } }
        publishedAt: { ne: null }
        status: { eq: "accepted" }
      }
    ) {
      edges {
        node {
          id
          publishedAt
          title
          _rawBody
          _rawExcerpt
          slug {
            current
          }
          abbreviation {
            current
          }
        }
      }
    }
  }
`

const IndexPage = props => {
  const [isInputFocus, setIsInputFocus] = useState(null)
  const [hasResults, setHasResults] = useState(null)
  const [isSearchMode, setIsSearchMode] = useState(false)
  const [pageColors, setPageColors] = useState([])

  const { data, errors } = props

  const resultsCallback = val => {
    setHasResults(val)
  }

  const inputFocusCallback = val => {
    setIsInputFocus(val)
  }

  const searchModeCallback = val => {
    setIsSearchMode(val)
  }

  const colorsCallback = val => {
    console.log("Colors: ", val[0], val[1])
  }

  useEffect(() => {
    setPageColors([theming.color.bg, theming.color.text])
  }, [])

  if (errors) {
    return (
      <Layout>
        <GraphQLErrorList errors={errors} />
      </Layout>
    )
  }
  return (
    <DefaultLayout>
      <SEO title="Whatis.Finance" location={props.location} />
      <PageLayout siteTitle={data.site.title} colorsCallback={colorsCallback}>
        <SearchContainer
          isInputFocus={isInputFocus}
          isSearchMode={isSearchMode}
          hasResults={hasResults}
          className="SearchContainer"
        >
          <SearchWrapper
            isInputFocus={isInputFocus}
            isSearchMode={isSearchMode}
          >
            <Search
              posts={data.posts}
              pageColors={pageColors}
              location={"index"}
              inputFocusCallback={inputFocusCallback}
              searchModeCallback={searchModeCallback}
              resultsCallback={resultsCallback}
            />
          </SearchWrapper>
        </SearchContainer>
        {!hasResults && <MostPopular data={data.posts} />}
      </PageLayout>
    </DefaultLayout>
  )
}

const SearchContainer = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  text-align: center;
  margin-top: 20vh;
  height: calc(80vh - 36px);

  @media (max-width: 768px) {
    ${p =>
      p.isSearchMode &&
      css`
        background-color: #273739;
        position: fixed;
        top: 0;
        left: 0;
        right: 0;
        margin-top: 0;
        z-index: 1;
        height: calc(100vh - 20px);
      `}
  }
`

const SearchWrapper = styled(Wrapper)`
  display: flex;
  flex-direction: column;
  height: 100%;
  @media (max-width: 768px) {
    ${p =>
      p.isSearchMode &&
      css`
        position: relative;
        top: 30px;
        transform: translateY(-10px);
        transition: transform 300ms ease-out;
      `}
  }
`

export default IndexPage
