import React from "react"
import AniLink from "gatsby-plugin-transition-link/AniLink"
import styled from "styled-components"
import { getBlogUrl } from "../lib/helpers"

const MostPopular = data => {
  const obj = data.data.edges
  const mostPopularWords = []
  obj.map(itm => {
    if (itm.node.abbreviation.current === "ROE") {
      return mostPopularWords.push(itm)
    }
    if (itm.node.abbreviation.current === "PSD2") {
      return mostPopularWords.push(itm)
    }
    if (itm.node.abbreviation.current === "AML") {
      return mostPopularWords.push(itm)
    }
    if (itm.node.abbreviation.current === "MiFID II") {
      return mostPopularWords.push(itm)
    }
    if (itm.node.abbreviation.current === "ROI") {
      return mostPopularWords.push(itm)
    }
    return null
  })
  return (
    <Wrapper>
      <PopularHeader>Mest populære ord</PopularHeader>
      <LinkWrapper>
        <Divider />
        {mostPopularWords.map(itm => {
          return (
            <PopularLink
              key={itm.node.id}
              to={getBlogUrl(itm.node.publishedAt, itm.node.slug.current)}
            >
              {itm.node.abbreviation.current}
            </PopularLink>
          )
        })}
      </LinkWrapper>
      <SeeAll to={"/list"}>Se alle</SeeAll>
      <SeeAll to={"/suggestWords"}> Foreslå ord </SeeAll>
    </Wrapper>
  )
}
export default MostPopular

const LinkWrapper = styled.div`
  text-align: center;
`

const PopularHeader = styled.h1`
  font-size: 25px;
`
const PopularLink = styled(AniLink)`
  font-size: 15px;
  font-weight: bold;
  text-decoration: none;
  margin: 7px;
  opacity: 0.5;
  :hover {
    opacity: 1;
  }
`
const SeeAll = styled(PopularLink)`
  font-size: 18px;
`
const Divider = styled.hr`
  width: 250px;
  margin-bottom: 10px;
`
const Wrapper = styled.div`
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-top: 40%;
  @media (max-width: 768px) {
    margin-top: 90%;
  }
`
